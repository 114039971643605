.css_dash_count {
    text-align: center;
    width: 30px;
    height: 30px;
    font-size: 1 rem;
    padding: 3px;
    border-radius: 50%;
   
    border: 1.5px solid black;
    font-weight: bolder;
    color: black;
    align-self:flex-end;
}

.css_current{

    background-color:#19D3C5 ;
    border: none;
}

.css_completed{
    background-color: black;
    color:#19D3C5 ;
    border: none;
}

.links {
  padding: 0 10px;
  padding-right: 10px;
  border-top: 1px solid #000;
  height: 1px;
  line-height: 0.1em;
  margin-left: 5px;
  margin-right: 5px;
  width:100px;
}

.box {
    display: flex;
   width: 60px;
  }
  
  hr {
    align-self: center;
    background: black;
    width: 70%; 
    height: 2px;
    margin-right: 7px;
    
  }

  .css_invisible_hr{

   width: 100px;
  }
  hr.pending {
    
    background: #19D3C5;
    border-color:#19D3C5; 
    color: #19D3C5;;
  
    
  }



  .navi_container {
   
    /*width: 45%;
    padding: 10px;*/
    text-align: center;
    margin-right: 0;
  cursor: pointer;
  }
  .navi_container:hover {
   
   
    transition: outline 0.6s linear;
    
  }
  
  .button {
    background: transparent;
    border: 2px solid;
    padding: 10px 40px;
    font-size: 15px;
    text-align: center;
    margin: 20px 10px 10px 0;
    font-weight: bold;
    display: inline-block;
  }

  .nav_control_container{   
    margin-top: 10px;
    margin-bottom: 5px;
    justify-content: center;
    align-items: center;
   width: 100%;
   
    
    
  }

  @media only screen and (max-width: 768px) {
    .nav_control_container{   
    
      padding-left: 0%;
    }



    .css_dash_count {
    width: 20px;
    height: 20px;
    font-size: 0.5em;
}
  hr {
    align-self: center;
    background: black;
    width: 100%; 
    height: 2px;
    margin-right: 7px;
    
  }
.box {
    display: flex;
   width: 40px;
  }

   }
