@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700;800&display=swap');


.circle1{
    background-image: url("../../../assets/images/ShapesPatterns/Ownly_Shape_Circle.svg");
}
.circle2{
    background-image: url("../../../assets/images/ShapesPatterns/Ownly_Shape_Circle_1.svg");
}

.circle3{
    background-image: url("../../../assets/images/ShapesPatterns/Ownly_Shape_Circle_2.svg");
}

.Squares1{
    background-image: url("../../../assets/images/ShapesPatterns/Ownly_Shape_Square-01.svg");
}
.Squares2{
    background-image: url("../../../assets/images/ShapesPatterns/Ownly_Shape_Square_1-01.svg");
}

.Squares3{
    background-image: url("../../../assets/images/ShapesPatterns/Ownly_Shape_Square_2-01.svg");
}


.Triangles1{
    background-image: url("../../../assets/images/ShapesPatterns/Ownly_Shape_Triangle-01.svg");
}
.Triangles2{
    background-image: url("../../../assets/images/ShapesPatterns/Ownly_Shape_Triangle_1.svg");
}

.Triangles3{
    background-image: url("../../../assets/images/ShapesPatterns/Ownly_Shape_Triangle_2.svg");
}

.Lines1{
    background-image: url("../../../assets/images/ShapesPatterns/Ownly_Shape_Line-01.svg");
}
.Lines2{
    background-image: url("../../../assets/images/ShapesPatterns/Ownly_Shape_Lines_1-01.svg");
}

.Lines3{
    background-image: url("../../../assets/images/ShapesPatterns/Ownly_Shape_Lines_2-01.svg");
}

.Rhombus1{
    background-image: url("../../../assets/images/ShapesPatterns/Ownly_Shape_Rhoubus_1-01.svg");
}
.Rhombus2{
    background-image: url("../../../assets/images/ShapesPatterns/Ownly_Shape_Rhoubus_2-01.svg");
}

.Rhombus3{
    background-image: url("../../../assets/images/ShapesPatterns/Ownly_Shape_Rhoubus_3-01.svg");
}


.Curves1{
    background-image: url("../../../assets/images/ShapesPatterns/Ownly_Shape_Curve_1-01.svg");  
}
.Curves2{
    background-image: url("../../../assets/images/ShapesPatterns/Ownly_Shape_Curve_2-01.svg");
}

.Curves3{
    background-image: url("../../../assets/images/ShapesPatterns/Ownly_Shape_Curve_3-01.svg");
}

#shapeImgCon {

    float: left;
    text-align: center;
    width: 100%;
}

#shapeImg {

    background-size: contain;
    background-position: center center;
        width: 150px;
    height: 100px;
        background-repeat: no-repeat;

}

.shape_image {
width: 150%;
    margin-left: -25%;
    
}