.container-card-selected{

    border:3px solid#19D3C5;
    padding: 10px;
  height: 100%;
  position: relative;
}

.container-card-unselected{

    border:3px solid#D0D3D4;
    padding: 10px;
    position: relative;
  
}

#fontCard {

    width: 100%;
}

.css_font_controller_main
.card-descriprion{

    
    overflow-wrap: break-word;
    text-align: center;
    vertical-align: middle;
 
    display: inline-block;
    margin: 0 auto;
   
}

.css_font_controller_main
{

    background-color: white;
    z-index: 1000;
}

.css_font_controller_main
.css_overview_description{

    inline-size: 160px;
    overflow-wrap: break-word;
    text-align: center;
    vertical-align: middle;
    height: 53px;
    display: inline-block;
    margin: 0 auto;
    font-size: 14px;
}
.css_font_controller_main
.card-header{
   
    font-weight: bolder;
    padding-top: 1em;
    font-size: 2em;

}
.css_select_toggle{


margin-top: 7px;
margin-left: 10px;
}

.logo {
    width:110%;
    height: 100%;
 
  
 
 /*   position: absolute;
    top: 200px;*/
  
}
.css_font_controller_main
.css_overview_caption{
font-weight: bolder;
text-decoration: underline;
text-decoration-color:#19D3C5;

}

.font_image {
width: 130%;
    margin-left: -15%;
    max-width: 200px;

}