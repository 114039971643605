.meter { 
    height: 5px;
    position: relative;
    background: #f3efe6;
    overflow: hidden;
}

.meter span {
    display: block;
    height: 100%;
}

.progress {
    background-color: #19D3C5;
    -webkit-animation: progressBar 5s ease-in-out;
    -webkit-animation-fill-mode:both; 
    -moz-animation: progressBar 5s ease-in-out;
    -moz-animation-fill-mode:both; 
}

@-webkit-keyframes progressBar {
  0% { width: 0; }
  100% { width: 100%; }
}

@-moz-keyframes progressBar {
  0% { width: 0; }
  100% { width: 100%; }
}