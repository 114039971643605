@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@400;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@400;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@400;700;800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lobster);
@import url(https://netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.min.css);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Nunito', sans-serif;
 
}



textarea{

  font-family: 'Nunito', sans-serif;
}

#backgroundGraphicBottom {
  position:fixed;
  bottom:0px;
  width:100%;
  height:0px;
  z-index: -20;
}

#root {
  height: 100vh;
  overflow: none;

}

.container {
  min-width: 100%;
  overflow: none;


  padding: 5px 30px;
  border-radius: 5px;
  position: relative;
}

.stdButton{
  float: right;
}

.inGrid {
  max-width: 1300px;
  margin: auto;

}

.header {
 
  padding-top: 20px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header p{
 
 font-size: 1em;
 font-weight: bold;
}

.btn {
  display: inline-block;
  background: #19D3C5;
  color: #1D252C;
  border: none;
  padding: 10px 40px;
  margin: 5px;
  border-radius: 200px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
  font-weight: 600;
  transition: 0.3s;
}

.btn:hover {
  background: #075E6D;
  color: #fff;
  transition: 0.3s;
}

.btn:focus {
  outline: none;
}

.btn:active {
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
}

.btn-block {
  display: block;
  width: 100%;
}

.task {
  background: #f4f4f4;
  margin: 5px;
  padding: 10px 20px;
  cursor: pointer;
  flex-grow:  1;

}

.task.reminder {
  border-left: 5px solid green;
}

.task h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tasksContainer{
  display: flex;
}


.add-form {
  margin-bottom: 40px;
}

.form-control {
  margin: 20px 0;
}

.form-control label {
  display: block;
}

.form-control input {
  width: 100%;
  height: 40px;
  margin: 5px;
  padding: 3px 7px;
  font-size: 17px;
}

.form-control-check {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-control-check label {
  flex: 1 1;
}

.form-control-check input {
  flex: 2 1;
  height: 20px;
}

/* Fonts */
h1 {
  font-weight: 800;
  font-size: 3.2em;
}

h3 {
  font-weight: 600;
  font-size: 0.7em;
  color: #19D3C5;
  text-transform: uppercase;

}

p {
  font-weight: 400;
  font-size: 0.7em;
  color: #1D252C;
}

Button {
  float: right;

}


footer {
  margin-top: 100px;
  text-align: center;
  display: flex;
}

/* Formating */

.btnContainer{
  flex-grow: 1;
}

.stepNavContainer{
  flex-grow: 1;
  margin-left: 10px;
  text-align: left;
}

/* background Graphic */
.rotate-container{
  position: absolute;
  left: -325px;
  bottom: 0px;
  width: 650px;
  height:  350px;
  z-index: -1;
  overflow: hidden;
  
}

.rotate-inner{
  background-image: url(/static/media/Ownly_Pattern_Full-01.6d37288d.svg);
  background-repeat: no-repeat;
  background-size: 650px;
  width: 655px;
  height:  655px;

  position: absolute;
 left: 0px;
 top: 0px;
 overflow: hidden;
}

.backgroundGraphic {
  background-image: url(/static/media/Ownly_Pattern_Full-01.6d37288d.svg);
  background-size: cover;
  position: absolute;
  left: -325px;
  bottom: -325px;
  width: 650px;
  height:  650px;
  overflow: hidden;
}


.description-label{

  color: #19D3C5;
  font-weight: bold;
  font-size: 1.15em;
}

.description-label
span
{ 
  font-size: 0.8em;
}


/*Animation**/

.rotate {
  -webkit-animation: rotation 32s infinite linear;
          animation: rotation 32s infinite linear;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

.page-contianer{
display: flex;
flex-direction: column;
min-height:100vh!important;

}

.content-wrap
{
  top:100px;
flex: 1 1;

}

.MuiTypography-body1{
  font-family: 'Nunito', sans-serif!important;
  font-weight: 700;

}

#input-with-icon-grid{
  font-family: 'Nunito', sans-serif!important;

}

.makeStyles-root-9 {

  padding: 0px 10px !important;
}

.makeStyles-root-10 {
  font-size: 18px !important;
  line-height: 1.65 !important;
}

.MuiButton-root {


}

#gridTopLevel {
 height: 100vh;
}

#gridHeaderSection {
padding-top: 4%;

}

#gridFooterSection {
    position: absolute;
    width: 100%;
    bottom: 0px;
    padding-bottom: 2%;
    
    z-index: 100;
}

.centerGrid {
  left: 0px;
  right: 0px;
}

.makeStyles-root-25{

      font-size: 18px !important;
}

#gridNavStyle {
display: "flex";
justify-content: center;
padding-top:"10px";
}

.search-results {
  padding-right: 10px;
}

.search-results .logo {
 width:  50px !important;
}

.slider_textL {

  text-align: right !important;
}

#mobilePadding{
 padding-bottom: 50px; 
}

.makeStatic{
  position: static !important;
}

.makeHeightAuto {
  height: auto !important;
}

.makeTransformZero{
  -webkit-transform: translateY(0%) !important;
          transform: translateY(0%) !important;
}

#centerflex {

  justify-content:  center;
}


#colHalf {

    padding-right: 25px;
}

#hideMobile {

    display: auto;
}

@media only screen and (max-width: 1200px) {
  #gridTopLevel {
      min-height: 100vh;
      height: auto;
  }

  #gridFooterSection {
      position: static;
      width: 100%;
      padding-bottom: 50px;

  }

  footer {
    margin-top: 0px;

  }


}


@media only screen and (max-width: 768px) {
 .rotate-container
 {
   display: none;
 }

 .stdButton{
  width:  100%;
}

#mobileHeight {

  padding:  40px 10px 60px 10px;
}



#colHalf {

    padding-right: 0px !important;
}

#hideMobile {

    display: none;
}

.stdButton button {
  width: 100% ;

}

.stdButton a {
  width: 100% ;

}

.container {
    padding: 5px 20px;
}


}

.page-box-container{

  z-index: 900;
}

.disable-action{

  pointer-events: none;
}




.css_dash_count {
    text-align: center;
    width: 30px;
    height: 30px;
    font-size: 1 rem;
    padding: 3px;
    border-radius: 50%;
   
    border: 1.5px solid black;
    font-weight: bolder;
    color: black;
    align-self:flex-end;
}

.css_current{

    background-color:#19D3C5 ;
    border: none;
}

.css_completed{
    background-color: black;
    color:#19D3C5 ;
    border: none;
}

.links {
  padding: 0 10px;
  padding-right: 10px;
  border-top: 1px solid #000;
  height: 1px;
  line-height: 0.1em;
  margin-left: 5px;
  margin-right: 5px;
  width:100px;
}

.box {
    display: flex;
   width: 60px;
  }
  
  hr {
    align-self: center;
    background: black;
    width: 70%; 
    height: 2px;
    margin-right: 7px;
    
  }

  .css_invisible_hr{

   width: 100px;
  }
  hr.pending {
    
    background: #19D3C5;
    border-color:#19D3C5; 
    color: #19D3C5;;
  
    
  }



  .navi_container {
   
    /*width: 45%;
    padding: 10px;*/
    text-align: center;
    margin-right: 0;
  cursor: pointer;
  }
  .navi_container:hover {
   
   
    transition: outline 0.6s linear;
    
  }
  
  .button {
    background: transparent;
    border: 2px solid;
    padding: 10px 40px;
    font-size: 15px;
    text-align: center;
    margin: 20px 10px 10px 0;
    font-weight: bold;
    display: inline-block;
  }

  .nav_control_container{   
    margin-top: 10px;
    margin-bottom: 5px;
    justify-content: center;
    align-items: center;
   width: 100%;
   
    
    
  }

  @media only screen and (max-width: 768px) {
    .nav_control_container{   
    
      padding-left: 0%;
    }



    .css_dash_count {
    width: 20px;
    height: 20px;
    font-size: 0.5em;
}
  hr {
    align-self: center;
    background: black;
    width: 100%; 
    height: 2px;
    margin-right: 7px;
    
  }
.box {
    display: flex;
   width: 40px;
  }

   }

.container-card-selected{

    border:3px solid#19D3C5;
    padding: 10px;
  height: 100%;
  position: relative;
  border-radius: 7px;
}


.container-card-unselected{

    border:3px solid#D0D3D4;
    padding: 10px;
    position: relative;
    border-radius: 7px;
}



.css_logo_controller_main
.card-descriprion{

    inline-size: 260px;
    overflow-wrap: break-word;
    text-align: center;
    vertical-align: middle;
    height: 53px;
    display: inline-block;
    margin: 0 auto;
   
}
.css_logo_controller_main
.card-header{
    font-family: 'Nunito', sans-serif;
    font-weight: bolder;
    padding-top: 1em;

}
.css_select_toggle{
position: absolute;
top: 5px;
right: 5px;

}
.css_logo_controller_main
.logo {
    width:110%;
    height: 100%;
 
  
 
 /*   position: absolute;
    top: 200px;*/
  
}

.logo_image{
    width: 70%;
    margin: 10px 15%;
}

.css_logo_controller_main{

    background-color: white;
}


.container-card-selected{

    border:3px solid#19D3C5;
    padding: 10px;
  height: 100%;
  position: relative;
}

.container-card-unselected{

    border:3px solid#D0D3D4;
    padding: 10px;
    position: relative;
  
}

#fontCard {

    width: 100%;
}

.css_font_controller_main
.card-descriprion{

    
    overflow-wrap: break-word;
    text-align: center;
    vertical-align: middle;
 
    display: inline-block;
    margin: 0 auto;
   
}

.css_font_controller_main
{

    background-color: white;
    z-index: 1000;
}

.css_font_controller_main
.css_overview_description{

    inline-size: 160px;
    overflow-wrap: break-word;
    text-align: center;
    vertical-align: middle;
    height: 53px;
    display: inline-block;
    margin: 0 auto;
    font-size: 14px;
}
.css_font_controller_main
.card-header{
   
    font-weight: bolder;
    padding-top: 1em;
    font-size: 2em;

}
.css_select_toggle{


margin-top: 7px;
margin-left: 10px;
}

.logo {
    width:110%;
    height: 100%;
 
  
 
 /*   position: absolute;
    top: 200px;*/
  
}
.css_font_controller_main
.css_overview_caption{
font-weight: bolder;
text-decoration: underline;
-webkit-text-decoration-color:#19D3C5;
        text-decoration-color:#19D3C5;

}

.font_image {
width: 130%;
    margin-left: -15%;
    max-width: 200px;

}
.Boldness{  
    background-image: url(/static/media/Ownly_Font_Boldness-01.7cadf9d7.svg);  
}
.Inviting{
    background-image: url(/static/media/Ownly_Font_Inviting-01.33c05bb0.svg);    
}

.Universal{ 
    background-image: url(/static/media/Ownly_Font_Universal-01.37f4869d.svg);
}
.Confidence{
    background-image: url(/static/media/Ownly_Font_Confidence-01.3baca2c5.svg);   
}

.Formal{
    background-image: url(/static/media/Ownly_Font_Formal-01.7eac64ae.svg);
}
.Established{
    background-image: url(/static/media/Ownly_Font_Established-01.59ce8b12.svg); 
}
.Modern-left{
    background-image: url(/static/media/Ownly_Font_Modern-01.d53c5ab6.svg);  
}
.Geometric{
    background-image: url(/static/media/Ownly_Font_Geometric-01.568fd7c1.svg);   
}
.Creativity{
    background-image: url(/static/media/Ownly_Font_Creativity-01.889c7d28.svg);  
}
.Personal{ 
    background-image: url(/static/media/Ownly_Font_Personal-01.25e9c973.svg);
}
.Comfort{  
    background-image: url(/static/media/Ownly_Font_Comfort-01.09669343.svg); 
}
.Clarity{ 
    background-image: url(/static/media/Ownly_Font_Clarity-01.35ed6d13.svg); 
}


.serif{
    background-image: url(/static/media/Ownly_Font_2-01.e8d745eb.svg); 
}
.san-serif{
    background-image: url(/static/media/Ownly_Font_5-01.357cfeaf.svg); 
}
.script{
    background-image: url(/static/media/Ownly_Font_3-01.2a1c833d.svg); 
}
.rounded{
    background-image: url(/static/media/Ownly_Font_1.e25602cb.svg); 
}
.modern{
    background-image: url(/static/media/Ownly_Font_6-01.78d26590.svg); 
}
.slab-serif{
    background-image: url(/static/media/Ownly_Font_4-01.2209348a.svg); 
}





.font-header-first-row{
    height: 55px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 200px 100px;
    padding: 10px;
}

.second-row-font-image{
    width: 130px;
    height: 45px;  
    background-repeat: no-repeat;
    background-size: 130px;
}
.left-img{
    background-position: right;
}
.right-img{
    background-position: left;
}


.css_color_palette_controller_main
.container-card-selected{

    border:5px solid#19D3C5;
    padding: 10px;
  height: 100%;
  position: relative;
}
.css_color_palette_controller_main
.container-card-unselected{

    border:5px solid white;
    padding: 10px;
    position: relative;
  
}
.css_font_controller_main
.card-descriprion{

    
    overflow-wrap: break-word;
    text-align: center;
    vertical-align: middle;
 
    display: inline-block;
    margin: 0 auto;
   
}

.css_font_controller_main
.css_overview_description{

    inline-size: 160px;
    overflow-wrap: break-word;
    text-align: center;
    vertical-align: middle;
    height: 53px;
    display: inline-block;
    margin: 0 auto;
}
.css_font_controller_main
.card-header{
   
    font-weight: bolder;
    padding-top: 1em;
    font-size: 2em;

}
.css_select_toggle{
position: absolute;
top: 5px;
right: 5px;

}

.logo {
    width:110%;
    height: 100%;
 
  
 
 /*   position: absolute;
    top: 200px;*/
  
}
.css_font_controller_main
.css_overview_caption{
font-weight: bolder;
text-decoration: underline;
-webkit-text-decoration-color:#19D3C5;
        text-decoration-color:#19D3C5;

}

.css_colour_main_text{

text-align: center;
font-weight: bold;
font-size: 1.4em;
}
.css_colour_description{
    
text-align: center;
font-weight: bold;


}

.css_color_palette_shade_controller_main
.container-card-selected
{
border-radius: 0!important;

}

.css_color_palette_shade_controller_main
.container-card-unselected
{
border-radius: 0!important;

}
.red1{    background-color:#FC4444;}
.red2{    background-color:#F92B2B;}
.red3{    background-color:#ED0000;}
.red4{    background-color: #D60000 ;}
.red5{    background-color: #B03A2E;}
.red6{    background-color: #7F0101;}
/*Added for demo purpose Start*/
.red7{  background-color:#F99746}
.red8{  background-color:#F4802A}
.red9{  background-color:#F96908}
.red10{  background-color:#F26200}
.red11{  background-color:#C95100}
.red12{  background-color:#A84400}
/*Added for demo purpose End*/

.org1{  background-color:#F99746}
.org2{  background-color:#F4802A}
.org3{  background-color:#F96908}
.org4{  background-color:#F26200}
.org5{  background-color:#C95100}
.org6{  background-color:#A84400}
.org7{  background-color:#F99746}
.org8{  background-color:#F4802A}
.org9{  background-color:#F96908}
.org10{  background-color:#F26200}
.org11{  background-color:#C95100}
.org12{  background-color:#A84400}

.yel1{  background-color:#FFDF5A}
.yel2{  background-color:#FFD527}
.yel3{  background-color:#FFCE00}
.yel4{  background-color:#EABD00}
.yel5{  background-color:#D3AA00}
.yel6{  background-color:#BA9600}

.green1{  background-color:#A2FF92}
.green2{  background-color:#7EFC63}
.green3{  background-color:#55F92D}
.green4{  background-color:#39ED06}
.green5{  background-color:#38D305}
.green6{  background-color:#2B8C07}

.blue1	{  background-color:	#5A8DFF	}
.blue2	{  background-color:	#2976FF	}
.blue3	{  background-color:	#0068FF	}
.blue4	{  background-color:	#0065DD	}
.blue5	{  background-color:	#0059B2	}
.blue6	{  background-color:	#003663	}

.wgrey1	{  background-color:	#F9F9F9	}
.wgrey2	{  background-color:	#F1F1F2	}
.wgrey3	{  background-color:	#E6E7E8	}
.wgrey4	{  background-color:	#D0D2D3	}
.wgrey5	{  background-color:	#BBBDBF	}
.wgrey6	{  background-color:	#A6A8AB	}

.teal1	{  background-color:	#88FFF9	}
.teal2	{  background-color:	#29EFDB	}
.teal3	{  background-color:	#1BE0C8	}
.teal4	{  background-color:	#00CEAB	}
.teal5	{  background-color:	#00AA89	}
.teal6	{  background-color:	#007F67	}

.pink1	{  background-color:	#FF8AEE	}
.pink2	{  background-color:	#FF50EF	}
.pink3	{  background-color:	#FF00F3	}
.pink4	{  background-color:	#DB03DB	}
.pink5	{  background-color:	#B202B7	}
.pink6	{  background-color:	#8A0091	}

.purple1	{  background-color:	#C78DFF	}
.purple2	{  background-color:	#A150FF	}
.purple3	{  background-color:	#9A31FF	}
.purple4	{  background-color:	#8800F2	}
.purple5	{  background-color:	#7509D3	}
.purple6	{  background-color:	#500BAA	}

.brown1	{  background-color:	#AA7752	}
.brown2	{  background-color:	#965326	}
.brown3	{  background-color:	#723B17	}
.brown4	{  background-color:	#60290A	}
.brown5	{  background-color:	#471C07	}
.brown6	{  background-color:	#351103	}

.gold1	{  background-color:	#FFCA7D	}
.gold2	{  background-color:	#FFB452	}
.gold3	{  background-color:	#EA9024	}
.gold4	{  background-color:	#BF7626	}
.gold5	{  background-color:	#A56321	}
.gold6	{  background-color:	#8C480C	}

.black1	{  background-color:	#3D3D3D	}
.black2	{  background-color:	#353333	}
.black3	{  background-color:	#2D2B2B	}
.black4	{  background-color:	#232121	}
.black5	{  background-color:	#161515	}
.black6	{  background-color:	#000000	}

.css_colour_container{


box-sizing: border-box;

height: 130px;

}

.css_colour_inner_palette{
 
   height: 130px;
   width: 35px;

}


.css_colour_shade_inner_palette{
 
   height: 70px;
   width: 35px;

}
#individualColorCards {

	width: 8.3%;
	min-width: 80px;
}
.css_color_palette_shade_controller_main{
  
}

.css_select_toggle_cp {
    right: 10px;
    position: absolute;
}


.css_color_palette_shade_controller_main
.container-card-selected{

    border:5px solid#19D3C5;
    padding: 10px;
  height: 100%;
  position: relative;
}
.css_color_palette_shade_controller_main
.container-card-unselected{

    border-top:5px solid white;
    border-bottom:5px solid white;
    border-left: 0;
    border-right: 0;
    padding: 10px;
    position: relative;
    border-radius: 5px;
  
}
.css_font_controller_main
.card-descriprion{

    
    overflow-wrap: break-word;
    text-align: center;
    vertical-align: middle;
 
    display: inline-block;
    margin: 0 auto;
   
}

.css_font_controller_main
.css_overview_description{

    inline-size: 160px;
    overflow-wrap: break-word;
    text-align: center;
    vertical-align: middle;
    height: 53px;
    display: inline-block;
    margin: 0 auto;
}
.css_font_controller_main
.card-header{
   
    font-weight: bolder;
    padding-top: 1em;
    font-size: 2em;

}
.css_color_palette_shade_controller_main
.css_select_toggle{


}

.logo {
    width:110%;
    height: 100%;
 
  
 
 /*   position: absolute;
    top: 200px;*/
}


.css_font_controller_main
.css_overview_caption{
font-weight: bolder;
text-decoration: underline;
-webkit-text-decoration-color:#19D3C5;
        text-decoration-color:#19D3C5;

}

.css_colour_main_text{

text-align: center;
font-weight: bold;
font-size: 1.2em;
}
.css_colour_description{
    
text-align: center;
font-weight: bold;


}
.shade-red1{    background-color:#f7a5a7;}
.shade-red2{    background-color:#f69294;}
.shade-red3{    background-color:#f47f82;}
.shade-red4{    background-color: #f36c6f ;}
.shade-red5{    background-color: #f15054;}
.shade-red6{    background-color: #f0474b;}
/*Added for demo purpose Start*/
.shade-red7{  background-color:#ef3539}
.shade-red8{  background-color:#d3272b}
.shade-red9{  background-color:#b71b1f}
.shade-red10{  background-color:#9b1115}
.shade-red11{  background-color:#720609}
.shade-red12{  background-color:#480002}


.shade-org1{background-color:	#f99746	}
.shade-org2{background-color:	#f29040	}
.shade-org3{background-color:	#eb893a	}
.shade-org4{background-color:	#e58235	}
.shade-org5{background-color:	#de7b2f	}
.shade-org6{background-color:	#d77429	}
.shade-org7{background-color:	#d16e23	}
.shade-org8{background-color:	#ca671d	}
.shade-org9{background-color:	#c36017	}
.shade-org10{background-color:	#bc5912	}
.shade-org11{background-color:	#b5520c	}
.shade-org12{background-color:	#a84400	}


.shade-yel1{background-color:	#ffdf5a	}
.shade-yel2{background-color:	#f9d952	}
.shade-yel3{background-color:	#f4d34b	}
.shade-yel4{background-color:	#eecd44	}
.shade-yel5{background-color:	#e8c73c	}
.shade-yel6{background-color:	#e2c135	}
.shade-yel7{background-color:	#ddba2d	}
.shade-yel8{background-color:	#d7b425	}
.shade-yel9{background-color:	#d1ae1e	}
.shade-yel10{background-color:	#cba817	}
.shade-yel11{background-color:	#c5a20f	}
.shade-yel12{background-color:	#ba9600	}


.shade-green1{background-color:	#a2ff92	}
.shade-green2{background-color:	#98f586	}
.shade-green3{background-color:	#8eec7b	}
.shade-green4{background-color:	#84e26f	}
.shade-green5{background-color:	#70cf58	}
.shade-green6{background-color:	#67c54d	}
.shade-green7{background-color:	#5dbc41	}
.shade-green8{background-color:	#53b235	}
.shade-green9{background-color:	#49a92a	}
.shade-green10{background-color:	#3f9f1e	}
.shade-green11{background-color:	#359613	}
.shade-green12{background-color:	#2b8c07	}

.shade-blue1{background-color:	#5a8dff	}
.shade-blue2{background-color:	#5288fc	}
.shade-blue3{background-color:	#3c80f4	}
.shade-blue4{background-color:	#2d79ee	}
.shade-blue5{background-color:	#1e72e8	}
.shade-blue6{background-color:	#0065dd	}
.shade-blue7{background-color:	#0065dd	}
.shade-blue8{background-color:	#005fc9	}
.shade-blue9{background-color:	#0058b4	}
.shade-blue10{background-color:	#004c96	}
.shade-blue11{background-color:	#003f77	}
.shade-blue12{background-color:	#003663	}


.shade-wgrey1{background-color:	#f9f9f9	}
.shade-wgrey2{background-color:	#f2f3f3	}
.shade-wgrey3{background-color:	#ebecec	}
.shade-wgrey4{background-color:	#e4e6e6	}
.shade-wgrey5{background-color:	#dedfe0	}
.shade-wgrey6{background-color:	#d7d8d9	}
.shade-wgrey7{background-color:	#d0d2d3	}
.shade-wgrey8{background-color:	#c9cbcc	}
.shade-wgrey9{background-color:	#c2c4c6	}
.shade-wgrey10{background-color:	#bbbdbf	}
.shade-wgrey11{background-color:	#b4b6b8	}
.shade-wgrey12{background-color:	#a6a8ab	}


.shade-teal1{background-color:	#88fff9	}
.shade-teal2{background-color:	#76faf1	}
.shade-teal3{background-color:	#64f5e9	}
.shade-teal4{background-color:	#51f0e1	}
.shade-teal5{background-color:	#3fead8	}
.shade-teal6{background-color:	#2de5d0	}
.shade-teal7{background-color:	#1be0c8	}
.shade-teal8{background-color:	#17d0b8	}
.shade-teal9{background-color:	#12c0a8	}
.shade-teal10{background-color:	#0ba78f	}
.shade-teal11{background-color:	#058f77	}
.shade-teal12{background-color:	#007f67	}


.shade-pink1{background-color:	#ff8aee	}
.shade-pink2{background-color:	#ff7eed	}
.shade-pink3{background-color:	#ff68ed	}
.shade-pink4{background-color:	#ff3aee	}
.shade-pink5{background-color:	#ff00f3	}
.shade-pink6{background-color:	#ed00e5	}
.shade-pink7{background-color:	#da00d7	}
.shade-pink8{background-color:	#c800c8	}
.shade-pink9{background-color:	#b300b6	}
.shade-pink10{background-color:	#a800ad	}
.shade-pink11{background-color:	#94009a	}
.shade-pink12{background-color:	#8a0091	}


.shade-purple1{background-color:	#c78dff	}
.shade-purple2{background-color:	#bf7eff	}
.shade-purple3{background-color:	#b86eff	}
.shade-purple4{background-color:	#b05fff	}
.shade-purple5{background-color:	#a548ff	}
.shade-purple6{background-color:	#9a31ff	}
.shade-purple7{background-color:	#8e2bf1	}
.shade-purple8{background-color:	#8124e3	}
.shade-purple9{background-color:	#751ed4	}
.shade-purple10{background-color:	#6918c6	}
.shade-purple11{background-color:	#5c11b8	}
.shade-purple12{background-color:	#500baa	}


.shade-brown1{background-color:	#aa7752	}
.shade-brown2{background-color:	#9e6842	}
.shade-brown3{background-color:	#915934	}
.shade-brown4{background-color:	#854c27	}
.shade-brown5{background-color:	#793f1c	}
.shade-brown6{background-color:	#6c3412	}
.shade-brown7{background-color:	#60290a	}
.shade-brown8{background-color:	#592409	}
.shade-brown9{background-color:	#522007	}
.shade-brown10{background-color:	#4b1c06	}
.shade-brown11{background-color:	#401604	}
.shade-brown12{background-color:	#351103	}


.shade-gold1	{  background-color:	#FFCA7D	}
.shade-gold2	{  background-color:	#fbc06e	}
.shade-gold3	{  background-color:	#f8b75f	}
.shade-gold4	{  background-color:	#f4ad51}
.shade-gold5	{  background-color:	#ed9a33	}
.shade-gold6	{  background-color:	#ed9a33	}
.shade-gold7	{  background-color:	#e28a22	}
.shade-gold8	{  background-color:	#d37e1e	}
.shade-gold9	{  background-color:	#b36616	}
.shade-gold10	{  background-color:	#a45a12	}
.shade-gold11	{  background-color:	#9c5410	}
.shade-gold12	{  background-color:	#8C480C	}

.shade-black1	{  background-color:	#5B5B5B	}
.shade-black2	{  background-color:	#535353	}
.shade-black3	{  background-color:	#4b4b4c	}
.shade-black4	{  background-color:	#3f4040	}
.shade-black5	{  background-color:	#353534	}
.shade-black6	{  background-color:	#2D2C2C	}

.shade-black7	{  background-color:	#252525	}
.shade-black8	{  background-color:	#1e1e1e	}
.shade-black9	{  background-color:	#131313	}
.shade-black10	{  background-color:	#0b0b0b	}
.shade-black11	{  background-color:	#040404	}

.shade-black12	{  background-color:	#000000	}

textarea {
    resize: none;
    font-size: 17px;
    padding: 8px;
  
    border: 2px solid #99A3A4;
    border-radius: 5px;

 
    background-size: 30px 30px;
    background-position: 5px 5px;
    background-repeat: no-repeat;
    padding-left: 20px;
    text-indent: 12px;
  
  
  }

  .textarea-backgroung-ico{

    background-image: url(/static/media/Ownly_Comments_Icon-01.36eedfc0.svg);
    background-size: 20px 20px;
    background-position-y: 7px;
    background-position-x: 7px;
   
  }

  .css_comment_description{

    color:#19D3C5;
    font-weight: bold;
    font-size: 1.15em;
  }
  .css_comment_description span{
      font-size: 0.8em;
  }
  
  
.container-card-selected{

    border:3px solid#19D3C5;
    padding: 10px;
  height: 100%;
  position: relative;
}

.container-card-unselected{

    border:3px solid#D0D3D4;
    padding: 10px;
    position: relative;
  
}
.css_shape_pattern_controller_main
.card-descriprion{

    
    overflow-wrap: break-word;
    text-align: center;
    vertical-align: middle;
 
    display: inline-block;
    margin: 0 auto;
   
}

.css_shape_pattern_controller_main
.css_overview_description{

    inline-size: 160px;
    overflow-wrap: break-word;
    text-align: center;
    vertical-align: middle;
    height: 53px;
    display: inline-block;
    margin: 0 auto;
}
.css_shape_pattern_controller_main
.card-header{
   
    font-weight: bolder;
  
    font-size: 1.7em;

}
.css_select_toggle{
position: absolute;
top: 5px;
right: 5px;

}

.logo {
    width:110%;
    height: 100%;
 
  
 
 /*   position: absolute;
    top: 200px;*/
  
}
.css_shape_pattern_controller_main
.css_overview_caption{
font-weight: bolder;
text-decoration: underline;
-webkit-text-decoration-color:#19D3C5;
        text-decoration-color:#19D3C5;

}
.css_shape_pattern_controller_main{
    background-color: white;
}

.circle1{
    background-image: url(/static/media/Ownly_Shape_Circle.567e881a.svg);
}
.circle2{
    background-image: url(/static/media/Ownly_Shape_Circle_1.54be6545.svg);
}

.circle3{
    background-image: url(/static/media/Ownly_Shape_Circle_2.4a05ef14.svg);
}

.Squares1{
    background-image: url(/static/media/Ownly_Shape_Square-01.f6be5b7f.svg);
}
.Squares2{
    background-image: url(/static/media/Ownly_Shape_Square_1-01.d1152515.svg);
}

.Squares3{
    background-image: url(/static/media/Ownly_Shape_Square_2-01.4feb21c2.svg);
}


.Triangles1{
    background-image: url(/static/media/Ownly_Shape_Triangle-01.ccbb6f90.svg);
}
.Triangles2{
    background-image: url(/static/media/Ownly_Shape_Triangle_1.03579058.svg);
}

.Triangles3{
    background-image: url(/static/media/Ownly_Shape_Triangle_2.1893f909.svg);
}

.Lines1{
    background-image: url(/static/media/Ownly_Shape_Line-01.4747fadf.svg);
}
.Lines2{
    background-image: url(/static/media/Ownly_Shape_Lines_1-01.f8fb7e65.svg);
}

.Lines3{
    background-image: url(/static/media/Ownly_Shape_Lines_2-01.4cd021fa.svg);
}

.Rhombus1{
    background-image: url(/static/media/Ownly_Shape_Rhoubus_1-01.c2191cf8.svg);
}
.Rhombus2{
    background-image: url(/static/media/Ownly_Shape_Rhoubus_2-01.774e4115.svg);
}

.Rhombus3{
    background-image: url(/static/media/Ownly_Shape_Rhoubus_3-01.f6c35205.svg);
}


.Curves1{
    background-image: url(/static/media/Ownly_Shape_Curve_1-01.1820a50b.svg);  
}
.Curves2{
    background-image: url(/static/media/Ownly_Shape_Curve_2-01.697c6053.svg);
}

.Curves3{
    background-image: url(/static/media/Ownly_Shape_Curve_3-01.2f0c3588.svg);
}

#shapeImgCon {

    float: left;
    text-align: center;
    width: 100%;
}

#shapeImg {

    background-size: contain;
    background-position: center center;
        width: 150px;
    height: 100px;
        background-repeat: no-repeat;

}

.shape_image {
width: 150%;
    margin-left: -25%;
    
}




.pz-slider-container
.MuiSlider-root{
    color:black;
    border-left: 2px solid black;
    border-right: 2px solid black;
  
}

.pz-slider-container
.css_colour_main_text{
    text-align: left;
    font-weight: bold;
    font-size: 1.3em;

}
.pz-slider-container
.MuiSlider-track {

    height: 2px;
}
.pz-slider-container
.MuiSlider-rail{
    height: 2px;
    opacity: 100!important;
 
}

.slider_text p {
    font-size: 1.2em;
    font-weight: bold;
    text-align: left;
}

.slider_textL p{

        text-align: right;
}

@media only screen and (max-width: 768px) {
    .slider_text p{
        font-size: 0.65em;
        padding-top: 12px;

    }
    /*
     .MuiSlider-thumb {
        margin-top: -2px !important;
    }
 */
    .smpadding {
        padding-left: 0px !important;

    }

    .slider_textL {
       padding-left: 0px !important;

    }

    
   
   

}

.MuiSlider-root{
  
   text-align: center;
}



/*
.MuiSlider-thumb {
    margin-top: -3px !important;
    margin-left: -12px !important;

}

.pz-slider-container
.MuiSlider-thumb{

    background-color: #19D3C5;
  width: 25px;
  height: 25px;
top:0.25em;

}



*/
.MuiSlider-thumb{

    background-color: #19D3C5!important;
    width: 12px!important;
    box-shadow: 0pt 0 0pt 7pt #19D3C5!important;
 

}
#exampleContainer {
	position: relative;
}

@media only screen and (max-width: 1200px) {
	#exampleContainer {
		position: static;
		width: 100%;
	}

	.css_string_builder_example{

		position: static !important;
		padding: 5px;
	}

	#sliderconbox {
		padding-left: 0px !important;
	}
}
.css_string_add_cycle{

    text-align: center;
    width: 22px;
    height: 22px;
    font-size: 1rem;
    padding: 0px;
    border-radius: 50%;
   
    border: 1.5px solid black;
    font-weight: bolder;
    color: black;
    align-self:flex-end;
color: white;

    background-color:#19D3C5 ;
    border: none;
    float: right;
}



.css_string_build_button{

    color: black;
    display: inline-block;
    border: 2px solid #5D6D7E;
    padding: 3px;
    border-radius: 30px;
    text-transform: none;
    font-size: 1em;
    margin: 5px;
    cursor: pointer;
}
.pz_css_label_text{
   
    padding-left: 10px;
    padding-right: 10px;
    white-space: nowrap;
    display: inline-block;
}

.css_string_builder_main_container{
    width:  95%;
    border: 2px solid #5D6D7E;
    display: inline-block;
    padding: 5px;
    border-radius: 15px;
    background-color: white;

}

.css_string_builder_main_container
textarea{
height: 200px;
border: none;
width: 100%;

}

.css_string_builder_main_container
textarea:focus {
    outline: none !important;
    border:1px solid red;
    box-shadow: 0 0 10px #719ECE;
    
  }

  .css_string_builder_example{
    position: absolute;
    bottom: 0;


  }
  .css_string_builder_example_header{

font-weight: bold;
font-size: 1.5em;
  }

  .css_string_builder_example_data{

    font-size: 1.2em;
  }
li.Mui-selected{

    background-color: #1ABC9C !important;
}

#topgap{
    padding-top: 15px;
}



.ddl-backgroung-ico{
    background-image: url(/static/media/Ownly_Target_Icon-01.de0ce0b8.svg)!important;
    background-repeat: no-repeat;
    background-size: 30px;
    background-position: 15px 10px;
    border-color: #99A3A4;

}

.pz-ddl-list
.MuiInput-input{
    background-color: transparent!important;
    font-weight: bold;
    color:gray;   
    padding-left: 50px;
   
}
.pz-ddl-list{

    padding-top: 10px;
    padding-bottom: 10px;
    border: 2px solid white; 
    border-radius: 25px;


}


.MuiMenu-list{
   
    border-bottom: 2px solid #99A3A4;
    border-left: 2px solid #99A3A4;
    border-right: 2px solid #99A3A4;
    border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  
  }

  .MuiSelect-iconOpenpz {
   
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
   
    transition: border 2s;

}

.menu-item-top-border{
    border-top: 1px solid #99A3A4 ;
   
    margin-left: 15px;
    margin-right: 15px;
}

.pz-ddl-list
.MuiSvgIcon-root{
font-size: 1.6em;
font-weight: 100;
top:10px;
color: #808B96;


}

.pz-ddl-list
.MinimalSelect-paper-56{

    border-radius: 50px;
}
.MinimalSelect-list-8 li.Mui-selected{
    background-color:#19D3C5!important;
}

.MinimalSelect-list-8 li{
   
 padding-top: 5px!important;
 padding-bottom: 5px!important;
 padding-left: 55px;
 font-size: 1.1em;
    
}
.MinimalSelect-paper-7{
    border-radius: 0px;
    border-color: black;
    
    border-top-left-radius: 0px!important;
    border-top-right-radius: 0px!important;
    border-bottom-left-radius: 15px!important;
    border-bottom-right-radius: 15px!important;
    
    }


    .MuiPaper-elevation8{

        box-shadow: none!important;
    }



.pz-ddl-list
.MuiInput-input{

   
    padding-left: 50px;
}





@media only screen and (max-width: 768px) {
    .MuiPaper-root
    {
     background-color: red;
    }
   }

   @media only screen and (max-width: 200px) {
    .MuiPaper-root
    {
     background-color: green;
    }
   }
d





.MuiButton-contained:hover, .MuiButton-contained {
    box-shadow: none !important;
}

.MuiSelect-iconOpenpz {
   
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
   
    transition: border 2s;

}

.menu-item-top-border{
    border-top: 1px solid #99A3A4 ;
  
    margin-left: 15px;
}

.pz-ddl-list
.MuiSvgIcon-root{
font-size: 1.6em;
font-weight: 100;
top:10px;
color: #808B96;


}
.pz-ddl-list
.MuiInput-input{
 background-color: transparent!important;
 font-weight: bold;
 color:gray;

}
.pz-ddl-list
.MinimalSelect-paper-56{

    border-radius: 50px;
}





.pz-ddl-list {

box-shadow: inset 0 0 0 2px #99A3A4, inset 0 0 0 10px white; 
}

/*
.pz-ddl-list {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
   
   
    border: 5px solid black;
  
    margin: 5px;
}

.pz-ddl-list + .pz-ddl-list {
    border: 5px solid red;
}

*/







.css_file_type_description{
    padding: 10px;
  font-weight: bold;
  min-width: 200px;
}

.css_file_container{
  
  height: 200px;
  overflow-y: auto;
  
}

.css_file_remove_icon{

  cursor: pointer;
  padding: 5px 10px 5px 10px;
}

.css_file_remove_icon:hover{


 color: red;
 font-weight: bold;

}

.uploaded-file-css{

  text-decoration: underline;
  cursor: pointer;

}

.un-uploaded-file-css{
  pointer-events: auto!important;
 
}

.logo_small_endpage
{
width: 170px;
float: right;


}

#topgaplarge {

    padding-top: 5%;
}

.end-page-main-container
{
    background-color:#1D252C;


}

.top-title {
    color: white;
    font-size: x-large;
    font-weight: bolder;
   
}
.end-page-main-title {
    color: white;
    font-size: 7em;
    font-weight: 900;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;   
}



.end-page-main-container
.bottom-title {
    color: white;
    padding-top: 10px;
}
.checkbox-start-page {
    background-color: turquoise;
    color: turquoise;
    border-color: #44ffd1;
    border: 10px solid #bcbcbc;
    width: 100px;
    height: 100px;
}

.MuiTypography-body1 {
    color: white;
}


.end-page-main-container{

    padding-top: 5%;
    height: 100vh;
}
.container{
border-style: none;

}

.tittle-underline{
    background-color:#44ffd1 ;
    height: 8px;
    border-radius: 3px;
}


.logo_paper_plane{
    width: 100%;
    float: left;
}

.logo_paper_plane_mobile{
    width: 60%;
    float: left;
    display: none;
}

@media only screen and (max-width: 1200px) {
    .logo_paper_plane{
        float: left;    
        display: none;
    }

    .logo_paper_plane_mobile{
        display: block;
    }

}
   


/*Added */
.alert-popup{
position: absolute;
top: 10px;
right: 10px;
float: right;
}
.alert .inner {
  display: block;
  padding: 6px;
  margin: 6px;
  border-radius: 3px;
  border: 1px solid rgb(180,180,180);
  background-color: rgb(212,212,212);
}

.alert .close {
  float: right;
  margin: 3px 12px 0px 0px;
  cursor: pointer;
}

.alert .inner,.alert .close {
  color: rgb(88,88,88);
}

.alert input {
  display: none;
}

.alert input:checked ~ * {
  -webkit-animation-name: dismiss,hide;
          animation-name: dismiss,hide;
  -webkit-animation-duration: 300ms;
          animation-duration: 300ms;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 0s,100ms;
          animation-delay: 0s,100ms;
}

.alert.error .inner {
  border: 1px solid rgb(238,211,215);
  background-color: rgb(242,222,222);
}

.alert.error .inner,.alert.error .close {
  color: rgb(185,74,72);
}

.alert.success .inner {
  border: 1px solid rgb(214,233,198);
  background-color: rgb(223,240,216);
}

.alert.success .inner,.alert.success .close {
  color: rgb(70,136,71);
}

.alert.info .inner {
  border: 1px solid rgb(188,232,241);
  background-color: rgb(217,237,247);
}

.alert.info .inner,.alert.info .close {
  color: rgb(58,135,173);
}

.alert.warning .inner {
  border: 1px solid rgb(251,238,213);
  background-color: rgb(252,248,227);
}

.alert.warning .inner,.alert.warning .close {
  color: rgb(192,152,83);
}

@-webkit-keyframes dismiss {
  0% {
    opacity: 1;
  }
  90%, 100% {
    opacity: 0;
    font-size: 0.1px;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@keyframes dismiss {
  0% {
    opacity: 1;
  }
  90%, 100% {
    opacity: 0;
    font-size: 0.1px;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@-webkit-keyframes hide {
  100% {
    height: 0px;
    width: 0px;
    overflow: hidden;
    margin: 0px;
    padding: 0px;
    border: 0px;
  }
}

@keyframes hide {
  100% {
    height: 0px;
    width: 0px;
    overflow: hidden;
    margin: 0px;
    padding: 0px;
    border: 0px;
  }
}
  
.meter { 
    height: 5px;
    position: relative;
    background: #f3efe6;
    overflow: hidden;
}

.meter span {
    display: block;
    height: 100%;
}

.progress {
    background-color: #19D3C5;
    -webkit-animation: progressBar 5s ease-in-out;
    -webkit-animation-fill-mode:both; 
    -moz-animation: progressBar 5s ease-in-out;
    -moz-animation-fill-mode:both; 
}

@-webkit-keyframes progressBar {
  0% { width: 0; }
  100% { width: 100%; }
}



.start-page-main-container
{
background-color:#1D252C;
height: 100vh;
overflow: hidden;


}
.top-title {
    color: white;
   
}
.start-page-main-title {
    color: white;
    font-size: 96px;
    font-weight: bolder;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  
  
   
}
.bottom-title {
    color: white;
    padding-top: 10px;
    font-size: 1.1em;
}
.checkbox-start-page {
    background-color: turquoise;
    color: turquoise;
    border-color: #44ffd1;
    border: 10px solid #bcbcbc;
    width: 100px;
    height: 100px;
}

.MuiTypography-body1 {
    color: white;
}


.start-page-main-container{

    padding: 5%;
}
.container{
border-style: none;

}

.logo_small{

width: 130px;
float: right;


}


.end-page-text-container{
    padding-top: 100px;
}

.start-page-main-container
.MuiInput-underline:before{

    border: 0px!important;

}
.start-page-main-container
.MuiInput-underline:after{

    border: 0px!important;

}



.email-control-start-page svg{
margin-top: 5px;   
}
.email-control-start-page input{
    margin-left: 5px;   
    }



.container-start-page{

  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);

  min-width: 100%;
 

  padding: 30px;
  border-radius: 5px;
  position: relative;
}



@media only screen and (max-width: 768px) {

.start-page-main-container {
    background-color:#1D252C;
    min-height: 100vh;
    height: auto;
    }

.container-start-page{

  top: 0px;
  -webkit-transform: translateY(0%);
          transform: translateY(0%);

 

  padding: 30px 10px;
  border-radius: 5px;
  position: static;
}

.end-page-main-title {
    font-size: 4em;
 
}

.logo_small {
    display: none;
 
}


}
