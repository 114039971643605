


.start-page-main-container
{
background-color:#1D252C;
height: 100vh;
overflow: hidden;


}
.top-title {
    color: white;
   
}
.start-page-main-title {
    color: white;
    font-size: 96px;
    font-weight: bolder;
    width: fit-content;
  
  
   
}
.bottom-title {
    color: white;
    padding-top: 10px;
    font-size: 1.1em;
}
.checkbox-start-page {
    background-color: turquoise;
    color: turquoise;
    border-color: #44ffd1;
    border: 10px solid #bcbcbc;
    width: 100px;
    height: 100px;
}

.MuiTypography-body1 {
    color: white;
}


.start-page-main-container{

    padding: 5%;
}
.container{
border-style: none;

}

.logo_small{

width: 130px;
float: right;


}


.end-page-text-container{
    padding-top: 100px;
}

.start-page-main-container
.MuiInput-underline:before{

    border: 0px!important;

}
.start-page-main-container
.MuiInput-underline:after{

    border: 0px!important;

}



.email-control-start-page svg{
margin-top: 5px;   
}
.email-control-start-page input{
    margin-left: 5px;   
    }



.container-start-page{

  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  min-width: 100%;
 

  padding: 30px;
  border-radius: 5px;
  position: relative;
}



@media only screen and (max-width: 768px) {

.start-page-main-container {
    background-color:#1D252C;
    min-height: 100vh;
    height: auto;
    }

.container-start-page{

  top: 0px;
  -ms-transform: translateY(0%);
  transform: translateY(0%);

 

  padding: 30px 10px;
  border-radius: 5px;
  position: static;
}

.end-page-main-title {
    font-size: 4em;
 
}

.logo_small {
    display: none;
 
}


}