.css_color_palette_controller_main
.container-card-selected{

    border:5px solid#19D3C5;
    padding: 10px;
  height: 100%;
  position: relative;
}
.css_color_palette_controller_main
.container-card-unselected{

    border:5px solid white;
    padding: 10px;
    position: relative;
  
}
.css_font_controller_main
.card-descriprion{

    
    overflow-wrap: break-word;
    text-align: center;
    vertical-align: middle;
 
    display: inline-block;
    margin: 0 auto;
   
}

.css_font_controller_main
.css_overview_description{

    inline-size: 160px;
    overflow-wrap: break-word;
    text-align: center;
    vertical-align: middle;
    height: 53px;
    display: inline-block;
    margin: 0 auto;
}
.css_font_controller_main
.card-header{
   
    font-weight: bolder;
    padding-top: 1em;
    font-size: 2em;

}
.css_select_toggle{
position: absolute;
top: 5px;
right: 5px;

}

.logo {
    width:110%;
    height: 100%;
 
  
 
 /*   position: absolute;
    top: 200px;*/
  
}
.css_font_controller_main
.css_overview_caption{
font-weight: bolder;
text-decoration: underline;
text-decoration-color:#19D3C5;

}

.css_colour_main_text{

text-align: center;
font-weight: bold;
font-size: 1.4em;
}
.css_colour_description{
    
text-align: center;
font-weight: bold;


}

.css_color_palette_shade_controller_main
.container-card-selected
{
border-radius: 0!important;

}

.css_color_palette_shade_controller_main
.container-card-unselected
{
border-radius: 0!important;

}