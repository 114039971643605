@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700;800&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Nunito', sans-serif;
 
}



textarea{

  font-family: 'Nunito', sans-serif;
}

#backgroundGraphicBottom {
  position:fixed;
  bottom:0px;
  width:100%;
  height:0px;
  z-index: -20;
}

#root {
  height: 100vh;
  overflow: none;

}

.container {
  min-width: 100%;
  overflow: none;


  padding: 5px 30px;
  border-radius: 5px;
  position: relative;
}

.stdButton{
  float: right;
}

.inGrid {
  max-width: 1300px;
  margin: auto;

}

.header {
 
  padding-top: 20px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header p{
 
 font-size: 1em;
 font-weight: bold;
}

.btn {
  display: inline-block;
  background: #19D3C5;
  color: #1D252C;
  border: none;
  padding: 10px 40px;
  margin: 5px;
  border-radius: 200px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
  font-weight: 600;
  transition: 0.3s;
}

.btn:hover {
  background: #075E6D;
  color: #fff;
  transition: 0.3s;
}

.btn:focus {
  outline: none;
}

.btn:active {
  transform: scale(0.98);
}

.btn-block {
  display: block;
  width: 100%;
}

.task {
  background: #f4f4f4;
  margin: 5px;
  padding: 10px 20px;
  cursor: pointer;
  flex-grow:  1;

}

.task.reminder {
  border-left: 5px solid green;
}

.task h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tasksContainer{
  display: flex;
}


.add-form {
  margin-bottom: 40px;
}

.form-control {
  margin: 20px 0;
}

.form-control label {
  display: block;
}

.form-control input {
  width: 100%;
  height: 40px;
  margin: 5px;
  padding: 3px 7px;
  font-size: 17px;
}

.form-control-check {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-control-check label {
  flex: 1;
}

.form-control-check input {
  flex: 2;
  height: 20px;
}

/* Fonts */
h1 {
  font-weight: 800;
  font-size: 3.2em;
}

h3 {
  font-weight: 600;
  font-size: 0.7em;
  color: #19D3C5;
  text-transform: uppercase;

}

p {
  font-weight: 400;
  font-size: 0.7em;
  color: #1D252C;
}

Button {
  float: right;

}


footer {
  margin-top: 100px;
  text-align: center;
  display: flex;
}

/* Formating */

.btnContainer{
  flex-grow: 1;
}

.stepNavContainer{
  flex-grow: 1;
  margin-left: 10px;
  text-align: left;
}

/* background Graphic */
.rotate-container{
  position: absolute;
  left: -325px;
  bottom: 0px;
  width: 650px;
  height:  350px;
  z-index: -1;
  overflow: hidden;
  
}

.rotate-inner{
  background-image: url("Ownly_Pattern_Full-01.svg");
  background-repeat: no-repeat;
  background-size: 650px;
  width: 655px;
  height:  655px;

  position: absolute;
 left: 0px;
 top: 0px;
 overflow: hidden;
}

.backgroundGraphic {
  background-image: url("Ownly_Pattern_Full-01.svg");
  background-size: cover;
  position: absolute;
  left: -325px;
  bottom: -325px;
  width: 650px;
  height:  650px;
  overflow: hidden;
}


.description-label{

  color: #19D3C5;
  font-weight: bold;
  font-size: 1.15em;
}

.description-label
span
{ 
  font-size: 0.8em;
}


/*Animation**/

.rotate {
  animation: rotation 32s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.page-contianer{
display: flex;
flex-direction: column;
min-height:100vh!important;

}

.content-wrap
{
  top:100px;
flex: 1;

}

.MuiTypography-body1{
  font-family: 'Nunito', sans-serif!important;
  font-weight: 700;

}

#input-with-icon-grid{
  font-family: 'Nunito', sans-serif!important;

}

.makeStyles-root-9 {

  padding: 0px 10px !important;
}

.makeStyles-root-10 {
  font-size: 18px !important;
  line-height: 1.65 !important;
}

.MuiButton-root {


}

#gridTopLevel {
 height: 100vh;
}

#gridHeaderSection {
padding-top: 4%;

}

#gridFooterSection {
    position: absolute;
    width: 100%;
    bottom: 0px;
    padding-bottom: 2%;
    
    z-index: 100;
}

.centerGrid {
  left: 0px;
  right: 0px;
}

.makeStyles-root-25{

      font-size: 18px !important;
}

#gridNavStyle {
display: "flex";
justify-content: center;
padding-top:"10px";
}

.search-results {
  padding-right: 10px;
}

.search-results .logo {
 width:  50px !important;
}

.slider_textL {

  text-align: right !important;
}

#mobilePadding{
 padding-bottom: 50px; 
}

.makeStatic{
  position: static !important;
}

.makeHeightAuto {
  height: auto !important;
}

.makeTransformZero{
  transform: translateY(0%) !important;
}

#centerflex {

  justify-content:  center;
}


#colHalf {

    padding-right: 25px;
}

#hideMobile {

    display: auto;
}

@media only screen and (max-width: 1200px) {
  #gridTopLevel {
      min-height: 100vh;
      height: auto;
  }

  #gridFooterSection {
      position: static;
      width: 100%;
      padding-bottom: 50px;

  }

  footer {
    margin-top: 0px;

  }


}


@media only screen and (max-width: 768px) {
 .rotate-container
 {
   display: none;
 }

 .stdButton{
  width:  100%;
}

#mobileHeight {

  padding:  40px 10px 60px 10px;
}



#colHalf {

    padding-right: 0px !important;
}

#hideMobile {

    display: none;
}

.stdButton button {
  width: 100% ;

}

.stdButton a {
  width: 100% ;

}

.container {
    padding: 5px 20px;
}


}

.page-box-container{

  z-index: 900;
}

.disable-action{

  pointer-events: none;
}



