.red1{    background-color:#FC4444;}
.red2{    background-color:#F92B2B;}
.red3{    background-color:#ED0000;}
.red4{    background-color: #D60000 ;}
.red5{    background-color: #B03A2E;}
.red6{    background-color: #7F0101;}
/*Added for demo purpose Start*/
.red7{  background-color:#F99746}
.red8{  background-color:#F4802A}
.red9{  background-color:#F96908}
.red10{  background-color:#F26200}
.red11{  background-color:#C95100}
.red12{  background-color:#A84400}
/*Added for demo purpose End*/

.org1{  background-color:#F99746}
.org2{  background-color:#F4802A}
.org3{  background-color:#F96908}
.org4{  background-color:#F26200}
.org5{  background-color:#C95100}
.org6{  background-color:#A84400}
.org7{  background-color:#F99746}
.org8{  background-color:#F4802A}
.org9{  background-color:#F96908}
.org10{  background-color:#F26200}
.org11{  background-color:#C95100}
.org12{  background-color:#A84400}

.yel1{  background-color:#FFDF5A}
.yel2{  background-color:#FFD527}
.yel3{  background-color:#FFCE00}
.yel4{  background-color:#EABD00}
.yel5{  background-color:#D3AA00}
.yel6{  background-color:#BA9600}

.green1{  background-color:#A2FF92}
.green2{  background-color:#7EFC63}
.green3{  background-color:#55F92D}
.green4{  background-color:#39ED06}
.green5{  background-color:#38D305}
.green6{  background-color:#2B8C07}

.blue1	{  background-color:	#5A8DFF	}
.blue2	{  background-color:	#2976FF	}
.blue3	{  background-color:	#0068FF	}
.blue4	{  background-color:	#0065DD	}
.blue5	{  background-color:	#0059B2	}
.blue6	{  background-color:	#003663	}

.wgrey1	{  background-color:	#F9F9F9	}
.wgrey2	{  background-color:	#F1F1F2	}
.wgrey3	{  background-color:	#E6E7E8	}
.wgrey4	{  background-color:	#D0D2D3	}
.wgrey5	{  background-color:	#BBBDBF	}
.wgrey6	{  background-color:	#A6A8AB	}

.teal1	{  background-color:	#88FFF9	}
.teal2	{  background-color:	#29EFDB	}
.teal3	{  background-color:	#1BE0C8	}
.teal4	{  background-color:	#00CEAB	}
.teal5	{  background-color:	#00AA89	}
.teal6	{  background-color:	#007F67	}

.pink1	{  background-color:	#FF8AEE	}
.pink2	{  background-color:	#FF50EF	}
.pink3	{  background-color:	#FF00F3	}
.pink4	{  background-color:	#DB03DB	}
.pink5	{  background-color:	#B202B7	}
.pink6	{  background-color:	#8A0091	}

.purple1	{  background-color:	#C78DFF	}
.purple2	{  background-color:	#A150FF	}
.purple3	{  background-color:	#9A31FF	}
.purple4	{  background-color:	#8800F2	}
.purple5	{  background-color:	#7509D3	}
.purple6	{  background-color:	#500BAA	}

.brown1	{  background-color:	#AA7752	}
.brown2	{  background-color:	#965326	}
.brown3	{  background-color:	#723B17	}
.brown4	{  background-color:	#60290A	}
.brown5	{  background-color:	#471C07	}
.brown6	{  background-color:	#351103	}

.gold1	{  background-color:	#FFCA7D	}
.gold2	{  background-color:	#FFB452	}
.gold3	{  background-color:	#EA9024	}
.gold4	{  background-color:	#BF7626	}
.gold5	{  background-color:	#A56321	}
.gold6	{  background-color:	#8C480C	}

.black1	{  background-color:	#3D3D3D	}
.black2	{  background-color:	#353333	}
.black3	{  background-color:	#2D2B2B	}
.black4	{  background-color:	#232121	}
.black5	{  background-color:	#161515	}
.black6	{  background-color:	#000000	}
