
.ddl-backgroung-ico{
    background-image: url(../../../assets/images/Control/Ownly_Target_Icon-01.svg)!important;
    background-repeat: no-repeat;
    background-size: 30px;
    background-position: 15px 10px;
    border-color: #99A3A4;

}

.pz-ddl-list
.MuiInput-input{
    background-color: transparent!important;
    font-weight: bold;
    color:gray;   
    padding-left: 50px;
   
}
.pz-ddl-list{

    padding-top: 10px;
    padding-bottom: 10px;
    border: 2px solid white; 
    border-radius: 25px;


}


.MuiMenu-list{
   
    border-bottom: 2px solid #99A3A4;
    border-left: 2px solid #99A3A4;
    border-right: 2px solid #99A3A4;
    border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  
  }

  .MuiSelect-iconOpenpz {
   
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
   
    transition: border 2s;

}

.menu-item-top-border{
    border-top: 1px solid #99A3A4 ;
   
    margin-left: 15px;
    margin-right: 15px;
}

.pz-ddl-list
.MuiSvgIcon-root{
font-size: 1.6em;
font-weight: 100;
top:10px;
color: #808B96;


}

.pz-ddl-list
.MinimalSelect-paper-56{

    border-radius: 50px;
}
.MinimalSelect-list-8 li.Mui-selected{
    background-color:#19D3C5!important;
}

.MinimalSelect-list-8 li{
   
 padding-top: 5px!important;
 padding-bottom: 5px!important;
 padding-left: 55px;
 font-size: 1.1em;
    
}
.MinimalSelect-paper-7{
    border-radius: 0px;
    border-color: black;
    
    border-top-left-radius: 0px!important;
    border-top-right-radius: 0px!important;
    border-bottom-left-radius: 15px!important;
    border-bottom-right-radius: 15px!important;
    
    }


    .MuiPaper-elevation8{

        box-shadow: none!important;
    }



.pz-ddl-list
.MuiInput-input{

   
    padding-left: 50px;
}





@media only screen and (max-width: 768px) {
    .MuiPaper-root
    {
     background-color: red;
    }
   }

   @media only screen and (max-width: 200px) {
    .MuiPaper-root
    {
     background-color: green;
    }
   }
d





.MuiButton-contained:hover, .MuiButton-contained {
    box-shadow: none !important;
}

.MuiSelect-iconOpenpz {
   
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
   
    transition: border 2s;

}

.menu-item-top-border{
    border-top: 1px solid #99A3A4 ;
  
    margin-left: 15px;
}

.pz-ddl-list
.MuiSvgIcon-root{
font-size: 1.6em;
font-weight: 100;
top:10px;
color: #808B96;


}
.pz-ddl-list
.MuiInput-input{
 background-color: transparent!important;
 font-weight: bold;
 color:gray;

}
.pz-ddl-list
.MinimalSelect-paper-56{

    border-radius: 50px;
}





.pz-ddl-list {

box-shadow: inset 0 0 0 2px #99A3A4, inset 0 0 0 10px white; 
}

/*
.pz-ddl-list {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
   
   
    border: 5px solid black;
  
    margin: 5px;
}

.pz-ddl-list + .pz-ddl-list {
    border: 5px solid red;
}

*/






