.logo_small_endpage
{
width: 170px;
float: right;


}

#topgaplarge {

    padding-top: 5%;
}

.end-page-main-container
{
    background-color:#1D252C;


}

.top-title {
    color: white;
    font-size: x-large;
    font-weight: bolder;
   
}
.end-page-main-title {
    color: white;
    font-size: 7em;
    font-weight: 900;
    width: fit-content;   
}



.end-page-main-container
.bottom-title {
    color: white;
    padding-top: 10px;
}
.checkbox-start-page {
    background-color: turquoise;
    color: turquoise;
    border-color: #44ffd1;
    border: 10px solid #bcbcbc;
    width: 100px;
    height: 100px;
}

.MuiTypography-body1 {
    color: white;
}


.end-page-main-container{

    padding-top: 5%;
    height: 100vh;
}
.container{
border-style: none;

}

.tittle-underline{
    background-color:#44ffd1 ;
    height: 8px;
    border-radius: 3px;
}


.logo_paper_plane{
    width: 100%;
    float: left;
}

.logo_paper_plane_mobile{
    width: 60%;
    float: left;
    display: none;
}

@media only screen and (max-width: 1200px) {
    .logo_paper_plane{
        float: left;    
        display: none;
    }

    .logo_paper_plane_mobile{
        display: block;
    }

}
   

