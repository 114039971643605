#exampleContainer {
	position: relative;
}

@media only screen and (max-width: 1200px) {
	#exampleContainer {
		position: static;
		width: 100%;
	}

	.css_string_builder_example{

		position: static !important;
		padding: 5px;
	}

	#sliderconbox {
		padding-left: 0px !important;
	}
}