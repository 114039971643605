.css_file_type_description{
    padding: 10px;
  font-weight: bold;
  min-width: 200px;
}

.css_file_container{
  
  height: 200px;
  overflow-y: auto;
  
}

.css_file_remove_icon{

  cursor: pointer;
  padding: 5px 10px 5px 10px;
}

.css_file_remove_icon:hover{


 color: red;
 font-weight: bold;

}

.uploaded-file-css{

  text-decoration: underline;
  cursor: pointer;

}

.un-uploaded-file-css{
  pointer-events: auto!important;
 
}
