.css_colour_container{


box-sizing: border-box;

height: 130px;

}

.css_colour_inner_palette{
 
   height: 130px;
   width: 35px;

}


.css_colour_shade_inner_palette{
 
   height: 70px;
   width: 35px;

}