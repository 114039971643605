@import url(https://fonts.googleapis.com/css?family=Lobster);

@import url(https://netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.min.css);

/*Added */
.alert-popup{
position: absolute;
top: 10px;
right: 10px;
float: right;
}
.alert .inner {
  display: block;
  padding: 6px;
  margin: 6px;
  border-radius: 3px;
  border: 1px solid rgb(180,180,180);
  background-color: rgb(212,212,212);
}

.alert .close {
  float: right;
  margin: 3px 12px 0px 0px;
  cursor: pointer;
}

.alert .inner,.alert .close {
  color: rgb(88,88,88);
}

.alert input {
  display: none;
}

.alert input:checked ~ * {
  animation-name: dismiss,hide;
  animation-duration: 300ms;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-delay: 0s,100ms;
}

.alert.error .inner {
  border: 1px solid rgb(238,211,215);
  background-color: rgb(242,222,222);
}

.alert.error .inner,.alert.error .close {
  color: rgb(185,74,72);
}

.alert.success .inner {
  border: 1px solid rgb(214,233,198);
  background-color: rgb(223,240,216);
}

.alert.success .inner,.alert.success .close {
  color: rgb(70,136,71);
}

.alert.info .inner {
  border: 1px solid rgb(188,232,241);
  background-color: rgb(217,237,247);
}

.alert.info .inner,.alert.info .close {
  color: rgb(58,135,173);
}

.alert.warning .inner {
  border: 1px solid rgb(251,238,213);
  background-color: rgb(252,248,227);
}

.alert.warning .inner,.alert.warning .close {
  color: rgb(192,152,83);
}

@keyframes dismiss {
  0% {
    opacity: 1;
  }
  90%, 100% {
    opacity: 0;
    font-size: 0.1px;
    transform: scale(0);
  }
}

@keyframes hide {
  100% {
    height: 0px;
    width: 0px;
    overflow: hidden;
    margin: 0px;
    padding: 0px;
    border: 0px;
  }
}
  