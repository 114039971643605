textarea {
    resize: none;
    font-size: 17px;
    padding: 8px;
  
    border: 2px solid #99A3A4;
    border-radius: 5px;

 
    background-size: 30px 30px;
    background-position: 5px 5px;
    background-repeat: no-repeat;
    padding-left: 20px;
    text-indent: 12px;
  
  
  }

  .textarea-backgroung-ico{

    background-image: url(../../../assets/images/Control/Ownly_Comments_Icon-01.svg);
    background-size: 20px 20px;
    background-position-y: 7px;
    background-position-x: 7px;
   
  }

  .css_comment_description{

    color:#19D3C5;
    font-weight: bold;
    font-size: 1.15em;
  }
  .css_comment_description span{
      font-size: 0.8em;
  }
  
  