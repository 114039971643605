@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700;800&display=swap');


.Boldness{  
    background-image: url("../../../assets/images/FontTypes/Ownly_Font_Boldness-01.svg");  
}
.Inviting{
    background-image: url("../../../assets/images/FontTypes/Ownly_Font_Inviting-01.svg");    
}

.Universal{ 
    background-image: url("../../../assets/images/FontTypes/Ownly_Font_Universal-01.svg");
}
.Confidence{
    background-image: url("../../../assets/images/FontTypes/Ownly_Font_Confidence-01.svg");   
}

.Formal{
    background-image: url("../../../assets/images/FontTypes/Ownly_Font_Formal-01.svg");
}
.Established{
    background-image: url("../../../assets/images/FontTypes/Ownly_Font_Established-01.svg"); 
}
.Modern-left{
    background-image: url("../../../assets/images/FontTypes/Ownly_Font_Modern-01.svg");  
}
.Geometric{
    background-image: url("../../../assets/images/FontTypes/Ownly_Font_Geometric-01.svg");   
}
.Creativity{
    background-image: url("../../../assets/images/FontTypes/Ownly_Font_Creativity-01.svg");  
}
.Personal{ 
    background-image: url("../../../assets/images/FontTypes/Ownly_Font_Personal-01.svg");
}
.Comfort{  
    background-image: url("../../../assets/images/FontTypes/Ownly_Font_Comfort-01.svg"); 
}
.Clarity{ 
    background-image: url("../../../assets/images/FontTypes/Ownly_Font_Clarity-01.svg"); 
}


.serif{
    background-image: url("../../../assets/images/FontTypes/Ownly_Font_2-01.svg"); 
}
.san-serif{
    background-image: url("../../../assets/images/FontTypes/Ownly_Font_5-01.svg"); 
}
.script{
    background-image: url("../../../assets/images/FontTypes/Ownly_Font_3-01.svg"); 
}
.rounded{
    background-image: url("../../../assets/images/FontTypes/Ownly_Font_1.svg"); 
}
.modern{
    background-image: url("../../../assets/images/FontTypes/Ownly_Font_6-01.svg"); 
}
.slab-serif{
    background-image: url("../../../assets/images/FontTypes/Ownly_Font_4-01.svg"); 
}





.font-header-first-row{
    height: 55px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 200px 100px;
    padding: 10px;
}

.second-row-font-image{
    width: 130px;
    height: 45px;  
    background-repeat: no-repeat;
    background-size: 130px;
}
.left-img{
    background-position: right;
}
.right-img{
    background-position: left;
}
