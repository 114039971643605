.css_string_add_cycle{

    text-align: center;
    width: 22px;
    height: 22px;
    font-size: 1rem;
    padding: 0px;
    border-radius: 50%;
   
    border: 1.5px solid black;
    font-weight: bolder;
    color: black;
    align-self:flex-end;
color: white;

    background-color:#19D3C5 ;
    border: none;
    float: right;
}



.css_string_build_button{

    color: black;
    display: inline-block;
    border: 2px solid #5D6D7E;
    padding: 3px;
    border-radius: 30px;
    text-transform: none;
    font-size: 1em;
    margin: 5px;
    cursor: pointer;
}
.pz_css_label_text{
   
    padding-left: 10px;
    padding-right: 10px;
    white-space: nowrap;
    display: inline-block;
}

.css_string_builder_main_container{
    width:  95%;
    border: 2px solid #5D6D7E;
    display: inline-block;
    padding: 5px;
    border-radius: 15px;
    background-color: white;

}

.css_string_builder_main_container
textarea{
height: 200px;
border: none;
width: 100%;

}

.css_string_builder_main_container
textarea:focus {
    outline: none !important;
    border:1px solid red;
    box-shadow: 0 0 10px #719ECE;
    
  }

  .css_string_builder_example{
    position: absolute;
    bottom: 0;


  }
  .css_string_builder_example_header{

font-weight: bold;
font-size: 1.5em;
  }

  .css_string_builder_example_data{

    font-size: 1.2em;
  }