.container-card-selected{

    border:3px solid#19D3C5;
    padding: 10px;
  height: 100%;
  position: relative;
}

.container-card-unselected{

    border:3px solid#D0D3D4;
    padding: 10px;
    position: relative;
  
}
.css_shape_pattern_controller_main
.card-descriprion{

    
    overflow-wrap: break-word;
    text-align: center;
    vertical-align: middle;
 
    display: inline-block;
    margin: 0 auto;
   
}

.css_shape_pattern_controller_main
.css_overview_description{

    inline-size: 160px;
    overflow-wrap: break-word;
    text-align: center;
    vertical-align: middle;
    height: 53px;
    display: inline-block;
    margin: 0 auto;
}
.css_shape_pattern_controller_main
.card-header{
   
    font-weight: bolder;
  
    font-size: 1.7em;

}
.css_select_toggle{
position: absolute;
top: 5px;
right: 5px;

}

.logo {
    width:110%;
    height: 100%;
 
  
 
 /*   position: absolute;
    top: 200px;*/
  
}
.css_shape_pattern_controller_main
.css_overview_caption{
font-weight: bolder;
text-decoration: underline;
text-decoration-color:#19D3C5;

}
.css_shape_pattern_controller_main{
    background-color: white;
}
