


.pz-slider-container
.MuiSlider-root{
    color:black;
    border-left: 2px solid black;
    border-right: 2px solid black;
  
}

.pz-slider-container
.css_colour_main_text{
    text-align: left;
    font-weight: bold;
    font-size: 1.3em;

}
.pz-slider-container
.MuiSlider-track {

    height: 2px;
}
.pz-slider-container
.MuiSlider-rail{
    height: 2px;
    opacity: 100!important;
 
}

.slider_text p {
    font-size: 1.2em;
    font-weight: bold;
    text-align: left;
}

.slider_textL p{

        text-align: right;
}

@media only screen and (max-width: 768px) {
    .slider_text p{
        font-size: 0.65em;
        padding-top: 12px;

    }
    /*
     .MuiSlider-thumb {
        margin-top: -2px !important;
    }
 */
    .smpadding {
        padding-left: 0px !important;

    }

    .slider_textL {
       padding-left: 0px !important;

    }

    
   
   

}

.MuiSlider-root{
  
   text-align: center;
}



/*
.MuiSlider-thumb {
    margin-top: -3px !important;
    margin-left: -12px !important;

}

.pz-slider-container
.MuiSlider-thumb{

    background-color: #19D3C5;
  width: 25px;
  height: 25px;
top:0.25em;

}



*/
.MuiSlider-thumb{

    background-color: #19D3C5!important;
    width: 12px!important;
    box-shadow: 0pt 0 0pt 7pt #19D3C5!important;
 

}