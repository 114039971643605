.container-card-selected{

    border:3px solid#19D3C5;
    padding: 10px;
  height: 100%;
  position: relative;
  border-radius: 7px;
}


.container-card-unselected{

    border:3px solid#D0D3D4;
    padding: 10px;
    position: relative;
    border-radius: 7px;
}



.css_logo_controller_main
.card-descriprion{

    inline-size: 260px;
    overflow-wrap: break-word;
    text-align: center;
    vertical-align: middle;
    height: 53px;
    display: inline-block;
    margin: 0 auto;
   
}
.css_logo_controller_main
.card-header{
    font-family: 'Nunito', sans-serif;
    font-weight: bolder;
    padding-top: 1em;

}
.css_select_toggle{
position: absolute;
top: 5px;
right: 5px;

}
.css_logo_controller_main
.logo {
    width:110%;
    height: 100%;
 
  
 
 /*   position: absolute;
    top: 200px;*/
  
}

.logo_image{
    width: 70%;
    margin: 10px 15%;
}

.css_logo_controller_main{

    background-color: white;
}