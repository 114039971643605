.shade-red1{    background-color:#f7a5a7;}
.shade-red2{    background-color:#f69294;}
.shade-red3{    background-color:#f47f82;}
.shade-red4{    background-color: #f36c6f ;}
.shade-red5{    background-color: #f15054;}
.shade-red6{    background-color: #f0474b;}
/*Added for demo purpose Start*/
.shade-red7{  background-color:#ef3539}
.shade-red8{  background-color:#d3272b}
.shade-red9{  background-color:#b71b1f}
.shade-red10{  background-color:#9b1115}
.shade-red11{  background-color:#720609}
.shade-red12{  background-color:#480002}


.shade-org1{background-color:	#f99746	}
.shade-org2{background-color:	#f29040	}
.shade-org3{background-color:	#eb893a	}
.shade-org4{background-color:	#e58235	}
.shade-org5{background-color:	#de7b2f	}
.shade-org6{background-color:	#d77429	}
.shade-org7{background-color:	#d16e23	}
.shade-org8{background-color:	#ca671d	}
.shade-org9{background-color:	#c36017	}
.shade-org10{background-color:	#bc5912	}
.shade-org11{background-color:	#b5520c	}
.shade-org12{background-color:	#a84400	}


.shade-yel1{background-color:	#ffdf5a	}
.shade-yel2{background-color:	#f9d952	}
.shade-yel3{background-color:	#f4d34b	}
.shade-yel4{background-color:	#eecd44	}
.shade-yel5{background-color:	#e8c73c	}
.shade-yel6{background-color:	#e2c135	}
.shade-yel7{background-color:	#ddba2d	}
.shade-yel8{background-color:	#d7b425	}
.shade-yel9{background-color:	#d1ae1e	}
.shade-yel10{background-color:	#cba817	}
.shade-yel11{background-color:	#c5a20f	}
.shade-yel12{background-color:	#ba9600	}


.shade-green1{background-color:	#a2ff92	}
.shade-green2{background-color:	#98f586	}
.shade-green3{background-color:	#8eec7b	}
.shade-green4{background-color:	#84e26f	}
.shade-green5{background-color:	#70cf58	}
.shade-green6{background-color:	#67c54d	}
.shade-green7{background-color:	#5dbc41	}
.shade-green8{background-color:	#53b235	}
.shade-green9{background-color:	#49a92a	}
.shade-green10{background-color:	#3f9f1e	}
.shade-green11{background-color:	#359613	}
.shade-green12{background-color:	#2b8c07	}

.shade-blue1{background-color:	#5a8dff	}
.shade-blue2{background-color:	#5288fc	}
.shade-blue3{background-color:	#3c80f4	}
.shade-blue4{background-color:	#2d79ee	}
.shade-blue5{background-color:	#1e72e8	}
.shade-blue6{background-color:	#0065dd	}
.shade-blue7{background-color:	#0065dd	}
.shade-blue8{background-color:	#005fc9	}
.shade-blue9{background-color:	#0058b4	}
.shade-blue10{background-color:	#004c96	}
.shade-blue11{background-color:	#003f77	}
.shade-blue12{background-color:	#003663	}


.shade-wgrey1{background-color:	#f9f9f9	}
.shade-wgrey2{background-color:	#f2f3f3	}
.shade-wgrey3{background-color:	#ebecec	}
.shade-wgrey4{background-color:	#e4e6e6	}
.shade-wgrey5{background-color:	#dedfe0	}
.shade-wgrey6{background-color:	#d7d8d9	}
.shade-wgrey7{background-color:	#d0d2d3	}
.shade-wgrey8{background-color:	#c9cbcc	}
.shade-wgrey9{background-color:	#c2c4c6	}
.shade-wgrey10{background-color:	#bbbdbf	}
.shade-wgrey11{background-color:	#b4b6b8	}
.shade-wgrey12{background-color:	#a6a8ab	}


.shade-teal1{background-color:	#88fff9	}
.shade-teal2{background-color:	#76faf1	}
.shade-teal3{background-color:	#64f5e9	}
.shade-teal4{background-color:	#51f0e1	}
.shade-teal5{background-color:	#3fead8	}
.shade-teal6{background-color:	#2de5d0	}
.shade-teal7{background-color:	#1be0c8	}
.shade-teal8{background-color:	#17d0b8	}
.shade-teal9{background-color:	#12c0a8	}
.shade-teal10{background-color:	#0ba78f	}
.shade-teal11{background-color:	#058f77	}
.shade-teal12{background-color:	#007f67	}


.shade-pink1{background-color:	#ff8aee	}
.shade-pink2{background-color:	#ff7eed	}
.shade-pink3{background-color:	#ff68ed	}
.shade-pink4{background-color:	#ff3aee	}
.shade-pink5{background-color:	#ff00f3	}
.shade-pink6{background-color:	#ed00e5	}
.shade-pink7{background-color:	#da00d7	}
.shade-pink8{background-color:	#c800c8	}
.shade-pink9{background-color:	#b300b6	}
.shade-pink10{background-color:	#a800ad	}
.shade-pink11{background-color:	#94009a	}
.shade-pink12{background-color:	#8a0091	}


.shade-purple1{background-color:	#c78dff	}
.shade-purple2{background-color:	#bf7eff	}
.shade-purple3{background-color:	#b86eff	}
.shade-purple4{background-color:	#b05fff	}
.shade-purple5{background-color:	#a548ff	}
.shade-purple6{background-color:	#9a31ff	}
.shade-purple7{background-color:	#8e2bf1	}
.shade-purple8{background-color:	#8124e3	}
.shade-purple9{background-color:	#751ed4	}
.shade-purple10{background-color:	#6918c6	}
.shade-purple11{background-color:	#5c11b8	}
.shade-purple12{background-color:	#500baa	}


.shade-brown1{background-color:	#aa7752	}
.shade-brown2{background-color:	#9e6842	}
.shade-brown3{background-color:	#915934	}
.shade-brown4{background-color:	#854c27	}
.shade-brown5{background-color:	#793f1c	}
.shade-brown6{background-color:	#6c3412	}
.shade-brown7{background-color:	#60290a	}
.shade-brown8{background-color:	#592409	}
.shade-brown9{background-color:	#522007	}
.shade-brown10{background-color:	#4b1c06	}
.shade-brown11{background-color:	#401604	}
.shade-brown12{background-color:	#351103	}


.shade-gold1	{  background-color:	#FFCA7D	}
.shade-gold2	{  background-color:	#fbc06e	}
.shade-gold3	{  background-color:	#f8b75f	}
.shade-gold4	{  background-color:	#f4ad51}
.shade-gold5	{  background-color:	#ed9a33	}
.shade-gold6	{  background-color:	#ed9a33	}
.shade-gold7	{  background-color:	#e28a22	}
.shade-gold8	{  background-color:	#d37e1e	}
.shade-gold9	{  background-color:	#b36616	}
.shade-gold10	{  background-color:	#a45a12	}
.shade-gold11	{  background-color:	#9c5410	}
.shade-gold12	{  background-color:	#8C480C	}

.shade-black1	{  background-color:	#5B5B5B	}
.shade-black2	{  background-color:	#535353	}
.shade-black3	{  background-color:	#4b4b4c	}
.shade-black4	{  background-color:	#3f4040	}
.shade-black5	{  background-color:	#353534	}
.shade-black6	{  background-color:	#2D2C2C	}

.shade-black7	{  background-color:	#252525	}
.shade-black8	{  background-color:	#1e1e1e	}
.shade-black9	{  background-color:	#131313	}
.shade-black10	{  background-color:	#0b0b0b	}
.shade-black11	{  background-color:	#040404	}

.shade-black12	{  background-color:	#000000	}
